import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

import { StripeService, Elements, Element as StripeElement, ElementsOptions } from 'ngx-stripe';
import { Stripe } from '../../model/stripe';
import { StripepayService } from '../../services/stripepay.service';

import { WorkArticle } from '../../model/workarticle';
import { WorkarticleService } from '../../services/workarticle.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit {
  elements: Elements;
  card: StripeElement;
  stripe: Stripe;

  cotizacionURL: SafeResourceUrl;
  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: 'en'
  };
  id: number;
  stripeTest: FormGroup;
  workarticle: WorkArticle[];
  workart: WorkArticle;
  total: number;
  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService, private stripepayService: StripepayService, private route: ActivatedRoute
    , private workarticleService: WorkarticleService) { }

  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id');
    this.getWorkArticlebyIDWork();
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#31325F',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: '#CFD7E0'
                }
              }
            }
          });
          this.card.mount('#card-element');
        }
      });
  }

  buy() {
    const stripepay = new Stripe;
    stripepay.Amount = this.total;
    stripepay.Description = "pruebafront";
    const name = this.stripeTest.get('name').value;
    this.stripeService
      .createToken(this.card, { name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          console.log(result.token);
          stripepay.StripeToken = result.token.id;
          this.getserviceAddress(stripepay);
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }
  getserviceAddress(stripe: Stripe) {

    this.stripepayService.pay(stripe)
      .subscribe(stripe1 => this.stripe = stripe1);



    return this.stripe;
  }
  getWorkArticlebyIDWork() {
    this.workarticleService.getWorkArticlesbyIDWork(this.id)
      .subscribe(workarticle => this.workarticle = workarticle,
        err => {
          alert(err);
        },
        async () => {
          this.total = this.workarticle.reduce((accumulator, current) => accumulator + current.Total, 0);
          //alert(this.total);
        });
    
    
    return this.workarticle;
  }
}
