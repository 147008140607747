interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Configuration',
    url: '/icons',
    icon: 'icon-star',
    children: [
      {
        name: 'Company Profile',
        url: '/rainshield/companyprofile',
        icon: 'icon-puzzle'
      },
      {
        name: 'States',
        url: '/rainshield/states',
        icon: 'icon-puzzle'
      },
      {
        name: 'Cities',
        url: '/rainshield/cities',
        icon: 'icon-puzzle'
      },
      {
        name: 'Article Category',
        url: '/rainshield/articlecategories',
        icon: 'icon-puzzle'
      },

      {
        name: 'Articles',
        url: '/rainshield/articles',
        icon: 'icon-puzzle'
      },
      {
        name: 'Customer Types',
        url: '/rainshield/customertypes',
        icon: 'icon-puzzle'
      },
      {
        name: 'Measurements',
        url: '/rainshield/measurements',
        icon: 'icon-puzzle'
      },
      {
        name: 'Security',
        url: '/rainshield/users',
        icon: 'icon-star',
        children: [
          {
            name: 'Users',
            url: '/rainshield/users',
            icon: 'icon-puzzle'
          },
          {
            name: 'Roles',
            url: '/rainshield/roles',
            icon: 'icon-puzzle'
          },
          {
            name: 'Permission',
            url: '/rainshield/permissions',
            icon: 'icon-puzzle'
          }]
      }]

  },
  {
    name: 'Customers',
    url: '/rainshield/customers',
    icon: 'icon-puzzle'
  },
  {
    name: 'Reports',
    url: '/rainshield/reports',
    icon: 'icon-puzzle'
  }
  ,
  {
    name: 'Payments',
    url: '/rainshield/stripe',
    icon: 'icon-puzzle'
  }

];
