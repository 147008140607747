import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { WorkArticle } from '../model/workarticle';
import { MessageService } from './message.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class WorkarticleService {
  private workarticlesUrl = 'https://www.drmonkey.co/daltex_data/WorkArticlesQ.php';

  constructor(
    private http: HttpClient,
    private messageService: MessageService) { }
  url = environment.base_Url1;
  /** GET workarticles from the server */
  getWorkArticles(): Observable<WorkArticle[]> {
    return this.http.get<WorkArticle[]>(this.url + '/getworkarticle')
      .pipe(
        tap(_ => this.log('fetched workarticles')),
        catchError(this.handleError('getworkarticle', []))
      );
  }
  /** GET workarticles from the server */
  getWorkArticlesOP(): Observable<WorkArticle[]> {
    return this.http.get<WorkArticle[]>(this.url + '/getworkarticleOP')
      .pipe(
        tap(_ => this.log('fetched workarticles')),
        catchError(this.handleError('getworkarticle', []))
      );
  }
  /** GET workarticles from the server */
  getWorkArticlesbyIDWork(term: number): Observable<WorkArticle[]> {
    return this.http.get<WorkArticle[]>(this.url + '/getworkarticlebyid?IDWork=' + term)
    .pipe(
      tap(_ => this.log('fetched workarticles')),
      catchError(this.handleError('getworkarticle', []))
    );
}
  /** GET workarticles from the server */
  getOperarios(): Observable<WorkArticle[]> {
    return this.http.get<WorkArticle[]>(this.url + '/getoperario')
      .pipe(
        tap(_ => this.log('fetched workarticles')),
        catchError(this.handleError('getWorkArticles', []))
      );
  }
  getInventario(): Observable<WorkArticle[]> {
    return this.http.get<WorkArticle[]>(this.url + 'WorkArticlesQ.php?comando=report')
      .pipe(
        tap(_ => this.log('fetched workarticles')),
        catchError(this.handleError('getWorkArticles', []))
      );
  }

  getWorkArticlesFilter(term: String): Observable<WorkArticle[]> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<WorkArticle[]>(this.url + 'WorkArticlesQ.php?', { params: { 'comando': 'query1', 'where': ' and i.insumo like "%' + term + '%"' } })
      .pipe(
        tap(_ => this.log('fetched workarticles')),
        catchError(this.handleError('getWorkArticles', []))
      );
  }
  getWorkArticlesdateFilter(term: String): Observable<WorkArticle[]> {
    return this.http.get<WorkArticle[]>(this.url + 'WorkArticlesQ.php?', { params: { 'comando': 'query1', 'where': ' and c.fecha= "' + term + '"' } })
      .pipe(
        tap(_ => this.log('fetched workarticles')),
        catchError(this.handleError('getWorkArticles', []))
      );
  }

  /** GET workarticle by id. Return `undefined` when id not found */
  getWorkArticleNo404<Data>(id: number): Observable<WorkArticle> {
    const url = `${this.workarticlesUrl}/?id=${id}`;
    return this.http.get<WorkArticle[]>(url)
      .pipe(
        map(workarticles => workarticles[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} workarticle id=${id}`);
        }),
        catchError(this.handleError<WorkArticle>(`getWorkArticle id=${id}`))
      );
  }

  /** GET workarticle by id. Will 404 if id not found */
  getWorkArticle(id: number): Observable<WorkArticle> {
    const url = `${this.workarticlesUrl}/${id}`;
    return this.http.get<WorkArticle>(this.url + 'WorkArticlesQ.php?comando=queryById&where= and IDWorkArticle=' + id).pipe(
      tap(_ => this.log(`fetched workarticle id=${id}`)),
      catchError(this.handleError<WorkArticle>(`getWorkArticle id=${id}`))
    );
  }

  /* GET workarticles whose name contains search term */
  searchWorkArticles(term: string): Observable<WorkArticle[]> {
    if (!term.trim()) {
      // if not search term, return empty workarticle array.
      return of([]);
    }
    return this.http.get<WorkArticle[]>(`${this.workarticlesUrl}/?name=${term}`).pipe(
      tap(_ => this.log(`found workarticles matching "${term}"`)),
      catchError(this.handleError<WorkArticle[]>('searchWorkArticles', []))
    );
  }

  //////// Save methods //////////

 /** POST: add a new workarticle to the server */
 addWorkArticle(workarticle: WorkArticle): Observable<WorkArticle> {
  return this.http.post<WorkArticle>(this.url + '/insertworkarticle', JSON.stringify(workarticle)).pipe(
    tap((newWorkArticle: WorkArticle) => this.log(`added workarticle w/ id=${newWorkArticle.IDWorkArticle}`)),
    catchError(this.handleError<WorkArticle>('addWorkArticle'))
  );
}

  /** DELETE: delete the workarticle from the server */
  deleteWorkArticle(workarticle: WorkArticle | number): Observable<WorkArticle> {
    const id = typeof workarticle === 'number' ? workarticle : workarticle.IDWorkArticle;
    const url = `${this.workarticlesUrl}/${id}`;

    return this.http.delete<WorkArticle>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted workarticle id=${id}`)),
      catchError(this.handleError<WorkArticle>('deleteWorkArticle'))
    );
  }

   /** PUT: update the workarticle on the server */
   updateWorkArticle(workarticle: WorkArticle): Observable<any> {
    return this.http.put( this.url + '/updateworkarticle', JSON.stringify(workarticle), httpOptions
    ).pipe(
      tap(_ => this.log(`updated workarticle id=${workarticle.IDWorkArticle}`)),
      catchError(this.handleError<any>('updateWorkArticle'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a WorkArticleService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`WorkArticleService: ${message}`);
  }
}
