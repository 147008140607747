import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Stripe } from '../model/stripe';
import { MessageService } from './message.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class StripepayService {
  private stripesUrl = 'https://www.drmonkey.co/daltex_data/StripesQ.php';

  constructor(
    private http: HttpClient,
    private messageService: MessageService) { }
  url = environment.base_Url1;
  /** GET stripes from the server */
  getStripes(): Observable<Stripe[]> {
    return this.http.get<Stripe[]>(this.url + '/getStripes')
      .pipe(
        tap(_ => this.log('fetched stripes')),
        catchError(this.handleError('getStripes', []))
      );
  }
  /** GET stripes from the server */
  getOperarios(): Observable<Stripe[]> {
    return this.http.get<Stripe[]>(this.url + '/getoperario')
      .pipe(
        tap(_ => this.log('fetched stripes')),
        catchError(this.handleError('getStripes', []))
      );
  }
  getInventario(): Observable<Stripe[]> {
    return this.http.get<Stripe[]>(this.url + 'StripesQ.php?comando=report')
      .pipe(
        tap(_ => this.log('fetched stripes')),
        catchError(this.handleError('getStripes', []))
      );
  }

  getStripesFilter(term: String): Observable<Stripe[]> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<Stripe[]>(this.url + 'StripesQ.php?', { params: { 'comando': 'query1', 'where': ' and i.insumo like "%' + term + '%"' } })
      .pipe(
        tap(_ => this.log('fetched stripes')),
        catchError(this.handleError('getStripes', []))
      );
  }
  getStripesdateFilter(term: String): Observable<Stripe[]> {
    return this.http.get<Stripe[]>(this.url + 'StripesQ.php?', { params: { 'comando': 'query1', 'where': ' and c.fecha= "' + term + '"' } })
      .pipe(
        tap(_ => this.log('fetched stripes')),
        catchError(this.handleError('getStripes', []))
      );
  }

  /** GET stripe by id. Return `undefined` when id not found */
  getStripeNo404<Data>(id: number): Observable<Stripe> {
    const url = `${this.stripesUrl}/?id=${id}`;
    return this.http.get<Stripe[]>(url)
      .pipe(
        map(stripes => stripes[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} stripe id=${id}`);
        }),
        catchError(this.handleError<Stripe>(`getStripe id=${id}`))
      );
  }

  /** GET stripe by id. Will 404 if id not found */
  getStripe(id: number): Observable<Stripe> {
    const url = `${this.stripesUrl}/${id}`;
    return this.http.get<Stripe>(this.url + 'StripesQ.php?comando=queryById&where= and Amount=' + id).pipe(
      tap(_ => this.log(`fetched stripe id=${id}`)),
      catchError(this.handleError<Stripe>(`getStripe id=${id}`))
    );
  }

  /* GET stripes whose name contains search term */
  searchStripes(term: string): Observable<Stripe[]> {
    if (!term.trim()) {
      // if not search term, return empty stripe array.
      return of([]);
    }
    return this.http.get<Stripe[]>(`${this.stripesUrl}/?name=${term}`).pipe(
      tap(_ => this.log(`found stripes matching "${term}"`)),
      catchError(this.handleError<Stripe[]>('searchStripes', []))
    );
  }

  //////// Save methods //////////

 /** POST: add a new stripe to the server */
 pay(stripe: Stripe): Observable<Stripe> {
  return this.http.post<Stripe>(this.url + '/payment', JSON.stringify(stripe)).pipe(
    tap((newStripe: Stripe) => this.log(`added stripe w/ id=${newStripe.Amount}`)),
    catchError(this.handleError<Stripe>('addStripe'))
  );
}

  /** DELETE: delete the stripe from the server */
  deleteStripe(stripe: Stripe | number): Observable<Stripe> {
    const id = typeof stripe === 'number' ? stripe : stripe.Amount;
    const url = `${this.stripesUrl}/${id}`;

    return this.http.delete<Stripe>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted stripe id=${id}`)),
      catchError(this.handleError<Stripe>('deleteStripe'))
    );
  }

   /** PUT: update the stripe on the server */
   updateStripe(stripe: Stripe): Observable<any> {
    return this.http.put( this.url + '/updateStripe', JSON.stringify(stripe), httpOptions
    ).pipe(
      tap(_ => this.log(`updated stripe id=${stripe.Amount}`)),
      catchError(this.handleError<any>('updateStripe'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a StripeService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`StripeService: ${message}`);
  }
}

